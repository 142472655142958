import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    GetMapPropertiesStatusesThunk,
    GetMapPropertiesThunk,
    GetMapSimilarPropertiesThunk,
    onSetMapProperties,
    onSetMapZoomCoordinates, selectIsDataLoading,
    selectMapDataPropertiesNotFound,
    selectMapProperties,
    selectMapPropertiesStatuses,
    selectMapPropertiesZoomCoordinates, selectSimilarMapProperties,
} from "../../store/propertiesMap";
import {
    Circle,
    FeatureGroup,
    MapContainer,
    Polygon,
    Popup,
    TileLayer,
    useMap,
} from "react-leaflet";
import L from "leaflet";
import UniversalInput from "../common/UniversalInput/UniversalInput";
import styles from "../AddressForm/AddressFrom.module.scss";
import {MapPropertyType} from "../../types/mapPropertiesTypes";
import s from './PropertiesMap.module.scss'
import {DataGridPro, GridRow, useGridApiRef} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import {FacebookCircularProgress} from "../AllPropertiesAddressForm/AllPropertiesAddressForm";
import {Empty, message, Select} from "antd";
import {getAbortController} from "../../helpers/AbortControllerManager";
import {QuestionCircleOutlined} from "@ant-design/icons";
import Tooltip from "../Tooltip/Tooltip";

const PropertiesMap = () => {
    const dispatch = useAppDispatch()
    const [searchInput, setSearchInput] = useState<string>("");
    // const [isLoading, setLoading] = useState<boolean>(true);
    const mapProperties = useAppSelector(selectMapProperties)
    const [searchPlace, setSearchPlace] = useState<any | null>(null);
    const [position, setPosition] = useState<L.LatLng | null>(null);
    const [height, setHeight] = useState(window.innerHeight);
    const [gridContentHeight, setGridContentHeight] = useState(height - 210);
    const mapStatuses = useAppSelector(selectMapPropertiesStatuses)
    const [mapZoom, setMapZoom] = useState(17)
    const [currentPointLatLng, setCurrentPointLatLng] = useState<any>()
    const [clickedPropertyId, setClickedPropertyId] = useState(0)
    const mapPropertiesNotFound = useAppSelector(selectMapDataPropertiesNotFound)
    const similarMapProperties = useAppSelector(selectSimilarMapProperties)
    const isLoading = useAppSelector(selectIsDataLoading)

    const onCancelRequest = () => {
        const controller = getAbortController();
        controller.abort();
    }


    const getAddressInfoFromPosition = async (lat: any, lng: any) => {
        try {
            const headers = new Headers();
            headers.append('accept-language', 'cz');
            const response = await fetch(
                `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}&city=Praha`,
                {headers}
            );

            if (response.ok) {
                const data = await response.json();
                if (data.error !== 'Unable to geocode') {
                    setSearchPlace(data);
                    // @ts-ignore
                    setPosition([data.lat, data.lon]);
                    return data
                }
            } else {
                throw new Error('Address not found');
            }
        } catch (error) {
            console.error('Error fetching address information:', error);
        } finally {

        }
    };
    const mapZoomCoordinates = useAppSelector(selectMapPropertiesZoomCoordinates)
    const [mapStatus, setMapStatus] = useState<null | string>('1')
    const [selectionModel, setSelectionModel] = useState([])
    useEffect(() => {
        if (mapProperties?.length) {
            const mapPropertyPosition = mapProperties.find((p: MapPropertyType) => p.PROP_ADDR_LATI !== null && p.PROP_ADDR_LONGI !== null)
            if (mapPropertyPosition) {
                getAddressInfoFromPosition(mapPropertyPosition?.PROP_ADDR_LATI, mapPropertyPosition?.PROP_ADDR_LONGI)
            }
        }
    }, [mapProperties])


    const selectMapStatuses = mapStatuses.map((status: any) => ({
        value: `${status.AE_NO_INDEX} `,
        label: `${status.AE_LI_VALUE}`,
    })).concat({value: `All`, label: `All`,})

    const similarMapPropertiesOptions = similarMapProperties.map((p: string) => ({
        value: p,
        label: p,
    }))

    useEffect(() => {
        // setLoading(true)

            dispatch(GetMapPropertiesThunk({searchData: {search_data: 'Staré Město', status_index: 1}}))

            // .then(() => {
            //     setLoading(false)
            //
            // })
    }, [dispatch])

    useEffect(() => {
        dispatch(GetMapPropertiesStatusesThunk())
    }, [dispatch])

    const onSetCurrentPointLatLng = (lat: any, lng: any, propertyId: number) => {
        setClickedPropertyId(propertyId);

        setCurrentPointLatLng((prev: any) => {
            if (prev?.lat === lat && prev?.lng === lng) {
                return prev; // Avoid re-setting the same values
            }
            return { lat, lng }; // Update only if lat/lng have changed
        });
    };

    const columns = [
        {
            field: '1',
            headerName: 'Properties',
            width: 380,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            minWidth: 280,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <div>
                            {data.row.XREF_PROPERTY}
                        </div>
                        <div style={{
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#111827'
                        }}>
                            {`${data.row.PROP_ADDR_STREET_ADDRESS !== null ? `${data.row.PROP_ADDR_STREET_ADDRESS},` : ''} ${data.row.PROP_ADDR_QUARTER !== null ? `${data.row.PROP_ADDR_QUARTER},` : ''} ${data.row.PROP_ADDR_CITY !== null ? `${data.row.PROP_ADDR_CITY}` : ''}`}

                        </div>
                    </div>

                )
            }
        },
        {
            field: '2',
            headerName: 'Status',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 600,
            minWidth: 280,
            width: 380,
            color: '#6B7280',
            fontSize: '12px',
            lineHeight: '20px',
            renderCell: (data: any) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <div style={{
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '20px',
                            color: '#111827'
                        }}>
                            {data.row.STATUS}
                        </div>
                    </div>

                )
            }
        },
    ]
    const OSMMap: React.FC<{ zoomLevel: number }> = ({zoomLevel}) => {
        const map = useMap();
        useEffect(() => {
            if (searchPlace) {
                const zoom = map.getZoom()
                const {lat, lon} = searchPlace;
                map.setView([lat, lon], zoom);
            }
            // eslint-disable-next-line
        }, [map, searchPlace]);
        useEffect(() => {
            map.setZoom(mapZoom)
        })

        // @ts-ignore
        useEffect(() => {
            if (currentPointLatLng) {
                // @ts-ignore
                const c = Object.values(map._layers).find((c: any) =>
                    c._latlng?.lat === currentPointLatLng?.lat &&
                    c._latlng?.lng === currentPointLatLng?.lng
                );

                // @ts-ignore
                if (c && c._popup !== null) {
                    // @ts-ignore
                    c?.openPopup(); // Open the popup only if it exists
                }
            }
            // @ts-ignore
        }, [currentPointLatLng, map._layers]);

        const previousTopLatitudeRef = useRef(null);
        const previousLeftLongitudeRef = useRef(null);

        const handleMapMove = () => {
            const bounds = map.getBounds();
            const topLatitude = bounds.getNorth();
            const bottomLatitude = bounds.getSouth();
            const leftLongitude = bounds.getWest();
            const rightLongitude = bounds.getEast();

            // Calculate the change in coordinates from the previous bounds
            const latChange = Math.abs(topLatitude - previousTopLatitudeRef.current!!);
            const lonChange = Math.abs(leftLongitude - previousLeftLongitudeRef!.current!!);

            // Define a threshold for what you consider a "small distance" (adjust as needed)
            const smallDistanceThreshold = 0.003; // Example threshold

            if (latChange > smallDistanceThreshold || lonChange > smallDistanceThreshold) {
                // Map has moved a significant distance, so update the coordinates and make the API call
                dispatch(
                    onSetMapZoomCoordinates({
                        lattop: bottomLatitude,
                        latbottom: topLatitude,
                        lantop: leftLongitude,
                        lanbottom: rightLongitude,
                    })
                );

                if (mapStatus !== null) {
                    dispatch(
                        GetMapPropertiesThunk({
                            searchData: {
                                zoom_request: {
                                    lattop: bottomLatitude,
                                    latbottom: topLatitude,
                                    lantop: leftLongitude,
                                    lanbottom: rightLongitude,
                                },
                                status_index: Number(mapStatus),
                            },
                        })
                    );
                } else if (searchInput !== '') {
                    dispatch(GetMapSimilarPropertiesThunk({ search_data: searchInput, status_index: Number(mapStatus) }));
                    dispatch(
                        GetMapPropertiesThunk({
                            searchData: {
                                zoom_request: {
                                    lattop: bottomLatitude,
                                    latbottom: topLatitude,
                                    lantop: leftLongitude,
                                    lanbottom: rightLongitude,
                                },
                                status_index: mapStatus !== null ? Number(mapStatus) : null,
                                search_data: searchInput,
                            },
                        })
                    );
                } else {
                    dispatch(
                        GetMapPropertiesThunk({
                            searchData: {
                                zoom_request: {
                                    lattop: bottomLatitude,
                                    latbottom: topLatitude,
                                    lantop: leftLongitude,
                                    lanbottom: rightLongitude,
                                },
                                status_index: mapStatus !== null ? Number(mapStatus) : null,
                            },
                        })
                    );
                }

                // Update the previous bounds for the next check
                // @ts-ignore
                previousTopLatitudeRef.current = topLatitude;
                // @ts-ignore
                previousLeftLongitudeRef.current = leftLongitude;
            }
        };

// Initialize previous coordinates with the initial map bounds
        let previousTopLatitude = map.getBounds().getNorth();
        let previousLeftLongitude = map.getBounds().getWest();
        useEffect(() => {
            map.on('moveend', handleMapMove);
            return () => {
                map.off('moveend', handleMapMove); // Remove the event listener on unmount
            };
        // eslint-disable-next-line
        }, [map]);
        return null;

    };
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSearchInput(value);

    };
    const debounce = (func: Function, wait: number) => {
        let timeout: ReturnType<typeof setTimeout> | null;
        return function (this: any, ...args: any[]) {
            const context = this;
            clearTimeout(timeout!);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    };
    // layout effect to always adapt grid height to the user screen device height
    useLayoutEffect(() => {
        const handleResize = debounce(function () {
            setHeight(window.innerHeight);
            const heightForPage = window.innerHeight;
            setGridContentHeight(heightForPage);
        }, 100);

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const onSearch = () => {
        if (searchInput !== '') {
            // setLoading(true)
            dispatch(GetMapSimilarPropertiesThunk({search_data: searchInput, status_index: Number(mapStatus)}))
            dispatch(GetMapPropertiesThunk({
                searchData: {search_data: searchInput, status_index: Number(mapStatus)},
                search_value: searchInput
            }))
                .then((res) => {
                    // setLoading(false)
                    // @ts-ignore
                    if (res.payload && (res.payload === null || res.payload.length === 0)) {
                        message.warn('Properties with this address were not found')
                    }
                })
        } else {
            message.warn('Please enter the address to search properties')
        }

    }

    //check reverse api request

    const handleCircleMouseOver = (e: any) => {
        e.target.openPopup();
        setCurrentPointLatLng(null)
        setSelectionModel([])
    }

    const handleCircleMouseOut = (e: any) => {
        setSelectionModel([])
        e.target.closePopup();
        setSelectionModel([])
    }

    const handleZoomChange = (value: string) => {
        if (value === 'Zoom 1km') {
            setMapZoom(18)
        } else if (value === 'Zoom 2km') {
            setMapZoom(17)
        } else if (value === 'Zoom 5km') {
            setMapZoom(16)
        } else if (value === 'Zoom 7km') {
            setMapZoom(15)
        }
    }

    const handleStatusChange = (value: string) => {
        setMapStatus(value)
        // setLoading(true)
        dispatch(onSetMapProperties())

        if (searchInput !== '') {
            dispatch(GetMapSimilarPropertiesThunk({search_data: searchInput, status_index: Number(mapStatus)}))
            dispatch(GetMapPropertiesThunk({
                searchData: {status_index: Number(value), search_data: searchInput},
                search_value: searchInput
            }))
                // .then(() => {
                //     setLoading(false)
                // })
        } else if (mapZoomCoordinates !== null) {
            dispatch(GetMapPropertiesThunk({
                searchData: {
                    status_index: Number(value),
                    zoom_request: mapZoomCoordinates
                }
            }))
                // .then(() => {
                //     setLoading(false)
                // })
        } else {
            dispatch(GetMapPropertiesThunk({searchData: {status_index: Number(value), search_data: 'Staré Město'}}))
                // .then(() => {
                //     setLoading(false)
                // })
        }

    }


    const onClearSearchInput = () => {
        setSearchInput('')
    }


    const apiRef = useGridApiRef();


    const selectionModelChange = (model: any) => {
        setSelectionModel(model)
    }


    const handleSimilarPropertiesSelect = (value: string) => {
        setSearchInput(`${value}%`)
    }


    console.log(mapProperties)

    return (
        <div
            className={s.inner}
            style={{
                height: gridContentHeight,
                maxHeight: gridContentHeight
            }}
        >
            <div className={s.inner__form}>
                <div className={s.inner__search}>
                    <div className={s.inner__inputBox}>
                        <UniversalInput
                            type="text"
                            value={searchInput}
                            onChange={handleSearchChange}
                            labelText={''}
                            withLabel={false}
                            lineDirection="vertical"
                            className={`${styles.formInput} mapInput`}
                            labelClassName={styles.formLabel}
                            // showError={isEmptyInput && !isFreePosition}
                            errorMessage={'Enter the correct address.'}
                        />
                        <div style={{
                            position: 'absolute',
                            top: '13px',
                            right: '15px'
                        }}>
                            {searchInput !== ''
                                &&
                                <p
                                    onClick={onClearSearchInput}
                                    style={{
                                        marginBottom: '0',
                                        fontSize: '12px',
                                        color: '#9CA3AF',
                                        cursor: "pointer"
                                    }}>x</p>
                            }
                        </div>
                    </div>
                    <div style={{
                        marginLeft: '16px'
                    }}>
                        <Tooltip
                            text="Please, use % in searching string, after/before the actual search value, for example Dobrovského% in order to find properties"
                            classname={'activitiesSearchInputTooltip'}>
                            <QuestionCircleOutlined/>
                        </Tooltip>
                    </div>
                    <button onClick={onSearch}>Search</button>
                </div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '12px',
                    marginBottom: '24px'
                }}>
                    <Select
                        style={{width: 105, marginRight: '12px'}}
                        className={'mapSelect'}
                        placeholder={'Status'}
                        suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
                                fill={'#191559'}/>
                        </svg>}
                        onChange={handleStatusChange}
                        defaultValue={'Active'}
                        options={selectMapStatuses}
                    />
                    <Select
                        style={{width: 116}}
                        defaultValue={'Zoom 2km'}
                        className={'mapSelect'}
                        suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
                                fill={'#191559'}/>
                        </svg>}
                        onChange={handleZoomChange}
                        options={[
                            {value: 'Zoom 1km', label: 'Zoom 1km'},
                            {value: 'Zoom 2km', label: 'Zoom 2km'},
                            {value: 'Zoom 5km', label: 'Zoom 5km'},
                            {value: 'Zoom 7km', label: 'Zoom 7km'},
                        ]}
                    />
                </div>
                <div>
                    {
                        mapProperties?.length && !isLoading
                            ?
                            <Box sx={{width: 500}}>
                                <DataGridPro
                                    sx={{
                                        borderRight: 'none',
                                        borderLeft: 'none',
                                        borderBottom: 'none',
                                        borderRadius: '0px',
                                        "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator": {
                                            display: "none"
                                        },
                                        "& .MuiDataGrid-cellContent": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#111827'
                                        },
                                        "& .MuiDataGrid-columnHeaderTitle": {
                                            fontFamily: 'Inter, sans-serif',
                                            fontWeight: 600,
                                            color: '#6B7280',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                        },
                                        "& .MuiDataGrid-row:hover": {
                                            backgroundColor: '#e2edf8'
                                        },
                                        "& .MuiDataGrid-row.Mui-selected": {
                                            backgroundColor: '#e2edf8'
                                        },
                                        // "& .MuiDataGrid-row.Mui-selected:hover": {
                                        //     backgroundColor: 'transparent'
                                        // },
                                        "& .MuiDataGrid-cell:focus": {
                                            outline: 'none'
                                        },
                                        "& .MuiDataGrid-toolbarContainer": {
                                            color: '#454545',
                                            position: 'absolute',
                                            top: '-50px',
                                            right: '0px'
                                        },
                                        // "& .MuiButtonBase-root": {
                                        //     fontFamily: 'Inter, sans-serif !important',
                                        //     fontWeight: 600,
                                        //     color: '#6B7280',
                                        //     fontSize: '12px',
                                        //     lineHeight: '20px',
                                        // },
                                        "& .MuiTablePagination-displayedRows": {
                                            margin: '0',
                                            fontFamily: 'Inter, sans-serif !important',
                                            fontWeight: 400,
                                            color: '#6B7280',
                                            fontSize: '12px',
                                            lineHeight: '20px',
                                        },
                                        "& .MuiPagination-root": {
                                            display: 'flex',
                                            justifyContent: 'center',
                                            padding: '7px 0px'
                                        },
                                        "& .MuiButtonBase-root:hover": {
                                            backgroundColor: '#bbd6f1',
                                            borderRadius: '0px'
                                        },
                                        "& .MuiButtonBase-root.MuiPaginationItem-root": {
                                            width: '40px',
                                            height: '40px',
                                            margin: 0,
                                            borderRight: '1px solid #D0D5DD',
                                            borderLeft: '1px solid #D0D5DD',
                                            borderTop: 'none',
                                            borderBottom: 'none',
                                            fontFamily: 'Inter, sans-serif !important',
                                            fontWeight: 500,
                                            color: '#1D2939',
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            borderRadius: '0px'
                                        },
                                        "& .MuiPagination-ul": {
                                            border: '1px solid #D0D5DD',
                                            borderRadius: '8px'
                                        },
                                        "& .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-previousNext": {
                                            borderRadius: '0px',
                                            border: 'none'
                                        },
                                        // "& .MuiDataGrid-row:nth-of-type(odd)": {
                                        //     backgroundColor: '#F9FAFB'
                                        // },
                                        "& .MuiDataGrid-virtualScrollerRenderZone": {
                                            width: '100%'
                                        },
                                        "& .MuiDataGrid-virtualScroller": {
                                            overflowY: 'scroll !important',
                                            overflowX: 'hidden',
                                            height: `${gridContentHeight}px !important`,
                                            // minHeight: height < 800 ? '460px' : 'auto',
                                        },
                                        // "& .MuiDataGrid-virtualScrollerContent": {
                                        //     height: `${gridContentHeight + 100}px !important`
                                        // },
                                        "& .MuiDataGrid-columnHeaders": {
                                            backgroundColor: '#FCFDFE'
                                        },
                                        "& .MuiDataGrid-cell": {
                                            borderBottom: 'none',
                                            cursor: 'pointer'
                                        },
                                        "& .MuiDataGrid-columnHeader--moving": {
                                            backgroundColor: 'transparent'
                                        }
                                    }}
                                    getRowId={e => e.id}
                                    columns={columns}
                                    rows={mapProperties}
                                    apiRef={apiRef}
                                    {...mapProperties}
                                    headerHeight={39}
                                    hideFooterSelectedRowCount
                                    autoPageSize={true}
                                    sortingOrder={['desc', 'asc']}
                                    autoHeight={true}
                                    selectionModel={selectionModel}
                                    onSelectionModelChange={(newSelection) => {
                                        selectionModelChange(newSelection);
                                    }}
                                    components={{
                                        Row: CustomGridRow,
                                    }}
                                    componentsProps={{
                                        row: {
                                            onSetCurrentPointLatLng,
                                            clickedPropertyId
                                        },
                                    }}
                                />
                            </Box>
                            :
                            !similarMapProperties.length
                            ?
                            <div style={{
                                marginTop: '50px'
                            }}>
                                <Empty description={'No data'}/>
                            </div>
                                :
                                null
                    }
                    {
                        mapPropertiesNotFound && similarMapProperties.length && !isLoading
                            ?
                            <>
                                <div >
                                    <Select
                                        style={{width: 233}}
                                        defaultValue={'Select similar address'}
                                        className={'mapSelect'}
                                        suffixIcon={<svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.30026 8.3L3.70026 5.7C3.38359 5.38333 3.31292 5.021 3.48826 4.613C3.66292 4.20433 3.97526 4 4.42526 4H9.57526C10.0253 4 10.3376 4.20433 10.5123 4.613C10.6876 5.021 10.6169 5.38333 10.3003 5.7L7.70026 8.3C7.60026 8.4 7.49192 8.475 7.37526 8.525C7.25859 8.575 7.13359 8.6 7.00026 8.6C6.86692 8.6 6.74192 8.575 6.62526 8.525C6.50859 8.475 6.40026 8.4 6.30026 8.3Z"
                                                fill={'#191559'}/>
                                        </svg>}
                                        onChange={handleSimilarPropertiesSelect}
                                        options={similarMapPropertiesOptions}
                                    />
                                </div>
                                <div style={{
                                    marginTop: '50px'
                                }}>
                                    <Empty description={'No properties matched your address was found, please use field above to pick one that is similar to the address you previous searched and try again'}/>
                                </div>
                            </>
                            :
                            null

                    }
                </div>
            </div>

            <>
                {isLoading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            background: 'rgba(255, 255, 255, 0.8)',
                            zIndex: 1000,
                        }}
                    >
                        <FacebookCircularProgress/>
                        <button className={s.cancelRequest}
                                onClick={onCancelRequest}>Cancel
                        </button>
                    </div>
                )}
                <MapContainer
                    center={position || [50.0874654, 14.4212535]} // Use the position state for the center
                    zoom={mapZoom}
                    style={{height: '100%', width: '100%'}}
                    scrollWheelZoom={false}
                    zoomControl={false}
                    // bounds={polygons}
                >
                    <div style={{
                        display: 'none'
                    }}>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    </div>

                    <OSMMap zoomLevel={mapZoom}/>
                    {
                        // eslint-disable-next-line
                        mapProperties?.filter((p: MapPropertyType) => p.SPOT_COLOUR !== null).map((p: MapPropertyType) => {
                            if (p.GPS_POINT) {
                                if (p.GPS_POINT.type === 'Polygon') {
                                    return (
                                        <FeatureGroup>
                                            {
                                                searchPlace &&
                                                <Popup>{`${p.PROP_ADDR_STREET_ADDRESS !== null ? `${p.PROP_ADDR_STREET_ADDRESS},` : ''} ${p.PROP_ADDR_QUARTER !== null ? `${p.PROP_ADDR_QUARTER},` : ''} ${p.PROP_ADDR_CITY !== null ? `${p.PROP_ADDR_CITY}` : ''}`}</Popup>
                                            }
                                            <Polygon eventHandlers={{
                                                // click: handlePolygonClick,
                                                mouseover: handleCircleMouseOver,
                                                mouseout: handleCircleMouseOut,
                                            }} positions={p.GPS_POINT.coordinates.map((row: any) => [row[1], row[0]])}
                                                     color={p.POLY_COLOUR}>
                                                <Popup>{`${p.PROP_ADDR_STREET_ADDRESS !== null ? `${p.PROP_ADDR_STREET_ADDRESS},` : ''} ${p.PROP_ADDR_QUARTER !== null ? `${p.PROP_ADDR_QUARTER},` : ''} ${p.PROP_ADDR_CITY !== null ? `${p.PROP_ADDR_CITY}` : ''}`}</Popup>
                                            </Polygon>
                                        </FeatureGroup>
                                    )
                                } else if (p.GPS_POINT.type === 'Point') {
                                    return (
                                        <FeatureGroup>
                                            {
                                                searchPlace &&
                                                <Popup>{`${p.PROP_ADDR_STREET_ADDRESS !== null ? `${p.PROP_ADDR_STREET_ADDRESS},` : ''} ${p.PROP_ADDR_QUARTER !== null ? `${p.PROP_ADDR_QUARTER},` : ''} ${p.PROP_ADDR_CITY !== null ? `${p.PROP_ADDR_CITY}` : ''}`}</Popup>
                                            }
                                            <Circle
                                                eventHandlers={{
                                                    // click: handlePolygonClick,
                                                    mouseover: handleCircleMouseOver,
                                                    mouseout: handleCircleMouseOut,
                                                }}
                                                center={p.GPS_POINT.coordinates.map((row: any) => [row[1], row[0]])[0]}
                                                color={p.SPOT_COLOUR}
                                                radius={mapZoom === 15 ? 20 : mapZoom === 16 ? 15 : 8}
                                            >
                                                <Popup>{`${p.PROP_ADDR_STREET_ADDRESS !== null ? `${p.PROP_ADDR_STREET_ADDRESS},` : ''} ${p.PROP_ADDR_QUARTER !== null ? `${p.PROP_ADDR_QUARTER},` : ''} ${p.PROP_ADDR_CITY !== null ? `${p.PROP_ADDR_CITY}` : ''}`}</Popup>
                                            </Circle>

                                        </FeatureGroup>
                                    )
                                }
                            }

                        })
                    }
                </MapContainer>
            </>


        </div>
    );
};


const CustomGridRow = React.memo((props: any) => {


    const {row} = props;
    const onRowClick = () => {
        props.onSetCurrentPointLatLng(row.PROP_ADDR_LATI, row.PROP_ADDR_LONGI, row.XREF_PROPERTY)
        // onSetClickedRow(row.XREF_PROPERTY)
        // dispatch(onSetPropertyId(row.XREF_PROPERTY))
    }
    // console.log(props)
    // console.log(row)
    return (
        <div
            onClick={onRowClick}

        >
            <GridRow {...props} />
        </div>


    );
});

export default PropertiesMap;
