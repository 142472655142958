import React, {useEffect, useState} from 'react';
import {Button, Form, FormInstance, Input} from "antd";
import s from "../Properties.module.scss";
import PropertyListComponent from "../PropertyListComponent";
import PropertyEditableMessage from "../PropertyEditableMessage/PropertyEditableMessage";
import {LoadingOutlined} from "@ant-design/icons";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    BrokerageCheckedReq,
    brokerageReqCheckedReqs,
    clearContactsPropertiesForEmail,
    clearPropertiesForEmail,
    clearSurfacesForEmail,
    CreatePropertiesEmailOffer,
    deleteAllPropertiesPropertyEmail,
    deletePropertyEmail,
    deleteSurfaceForEmail,
    GetPropertiesContactLang,
    GetPropertiesEmailBody,
    onChangeReqPropsWindowModesMode,
    onChangeWindowModesMode,
    onClearCheckedProperties,
    onClearCheckedSurfaces,
    onClearOwnerContacts,
    onDeleteAllPropertiesCheckedPropertyFromOffer,
    onDeleteCheckedPropertyFromOffer,
    onDeleteCheckedSurfacesFromOffer,
    onRemoveSurfacesFromOffer,
    onSetAllSurfaceClosed,
    onSetCurrentReq,
    onSetCurrentWindow,
    onSetEmailLinksAddText,
    onSetFilteredPropertiesOfferBCCContacts,
    onSetFilteredPropertiesOfferCCContacts,
    onSetFilteredPropertiesOfferToContacts,
    onSetIsSendBtnDisabled,
    onSetPropertiesMessageBody,
    onSetPropertiesOfferFormError,
    onSetSurfaceEmailLinksAddText,
    selectActivitiesModalOpened,
    selectAllPropertiesCurrentReq,
    selectAllPropertiesGridPropertiesForEmail,
    selectAllPropertiesGridWithReqGridReqId,
    selectDefaultContact,
    selectEmailBody,
    selectEmailDomain,
    selectEmailGreeting,
    selectEmailLinkText,
    selectEmailSubject,
    selectFilteredPropertiesOfferBCCContacts,
    selectFilteredPropertiesOfferCCContacts,
    selectFilteredPropertiesOfferToContacts,
    selectIsPropertiesToError,
    selectIsSendBtnDisabled,
    selectOwnerContacts,
    selectPropContactsWindowModes,
    selectPropertiesForEmail,
    selectPropertiesMessageBody,
    selectPropertyAgentAddress,
    selectSurfacesForEmail
} from "../../../store/propertiesReducer";
import {gridDataHelper} from "../../../helpers/localStorageHelper";
import shadowArrow from "../../../img/icons/shadow-arrow.png";
import houseImg from "../../../img/icons/no-image.svg";
import {selectUserData} from "../../../store/userReducer";
import {
    selectBrokeragePropertyOfferType,
    selectBrokerageReqCheckedProperties, selectProperty
} from "../../../store/brokerageReqsReducer";
import {selectCurrentScreenWidth} from "../../../store/utilityReducer";



interface PropertiesOfferModalType {
    hiddenModal: boolean
    height: number
    gridContentHeight: number
    onHideModal: () => void
    divRef: any,
    gridType: 'All Properties/REQ' | 'All Properties/Upcoming' | 'Properties' | 'Brokerage/Requirements' | 'Surfaces'
    contactLang: 'CZ' | 'EN',
    rowReqId?: number
    brokeragePropId?: number
    isBrokerage?: boolean
    isModalOpened?: boolean
    isModalHided?: boolean
    unHideModal?: () => void
}

const PropertiesOfferModal = ({
                                  hiddenModal,
                                  contactLang,
                                  gridContentHeight,
                                  onHideModal,
                                  divRef,
                                  gridType,
                                  rowReqId,
                                  isBrokerage,
                                  isModalOpened,
                                  isModalHided,
                                  unHideModal
                              }: PropertiesOfferModalType) => {
    const dispatch = useAppDispatch()
    const [emailBodyTextReplaced, setEmailBodyTextReplaced] = useState('')
    const propertiesGridPropertiesForEmail = useAppSelector(selectPropertiesForEmail)
    const allPropertiesGridPropertiesForEmail = useAppSelector(selectAllPropertiesGridPropertiesForEmail)
    const allPropertiesCurrentReq = useAppSelector(selectAllPropertiesCurrentReq)
    const [form] = Form.useForm<FormInstance>();
    const propertiesForEmail = gridType === 'Properties' ? propertiesGridPropertiesForEmail : allPropertiesGridPropertiesForEmail
    const surfacesForEmail = useAppSelector(selectSurfacesForEmail)
    const emailSubject = useAppSelector(selectEmailSubject)
    const defaultContact = useAppSelector(selectDefaultContact)
    const isPropertiesToError = useAppSelector(selectIsPropertiesToError)
    const propertiesLinkStart = window.env.OFFER_PROPERTIES_EXTERNAL_ADDR
    const gridRowId = gridDataHelper.getRowId()
    const emailDomain = useAppSelector(selectEmailDomain)
    const clickedSendBtn = useAppSelector(selectIsSendBtnDisabled)
    const emailLinkText = useAppSelector(selectEmailLinkText)
    let emailGreeting = useAppSelector(selectEmailGreeting)
    const emailBodyText = useAppSelector(selectEmailBody)
    let offerToContacts = useAppSelector(selectFilteredPropertiesOfferToContacts)
    const offerCCContacts = useAppSelector(selectFilteredPropertiesOfferCCContacts)
    const offerBCCContacts = useAppSelector(selectFilteredPropertiesOfferBCCContacts)
    const messageOfferBody = useAppSelector(selectPropertiesMessageBody)
    let agentAddress = useAppSelector(selectPropertyAgentAddress)
    const currentReqId = useAppSelector(selectAllPropertiesGridWithReqGridReqId)
    const userData = useAppSelector(selectUserData)
    const [isLoading, setIsLoading] = useState(false)
    const propContactsWindowModes = useAppSelector(selectPropContactsWindowModes)
    const activityButtonIsOpened = propContactsWindowModes?.find((m) => m.modeName === 'Activity')?.mode === 'Opened'
    const isCommonActivityModalOpened = useAppSelector(selectActivitiesModalOpened)
    const isActivityModalOpened = gridType === 'All Properties/Upcoming' ? activityButtonIsOpened : isCommonActivityModalOpened
    const brokerageReqCheckedProperties = useAppSelector(selectBrokerageReqCheckedProperties)
    const offerType = useAppSelector(selectBrokeragePropertyOfferType)
    const reqGridDomain = gridDataHelper.getReqGridDomain()
    const selectedBrokerageProperty = useAppSelector(selectProperty)
    const ownerContacts = useAppSelector(selectOwnerContacts)
    const defaultContacts = useAppSelector(selectDefaultContact)

    const toRegularCaseThirdWord = (str: string) => {
        const words = str.split(' ');
        if (words.length >= 3) {
            words[2] = words[2].charAt(0).toUpperCase() + words[2].slice(1).toLowerCase();
        }
        return words.join(' ');
    };
    emailGreeting = defaultContacts.length ?  toRegularCaseThirdWord(emailGreeting.message) : contactLang === 'CZ' ? `Vážený pane/pani,` : `Greeting,`
    const currentScreenWidth = useAppSelector(selectCurrentScreenWidth)

    useEffect(() => {
        if (gridType === 'Properties' && gridRowId !== false) {
            dispatch(GetPropertiesContactLang(gridRowId))
                .then((res) => {
                    dispatch(GetPropertiesEmailBody({req_id: gridRowId, gridType: gridType, lang: res.payload as "CZ" | "EN"}))
                    // .then(() => {
                    //     dispatch(GetPropertiesEmailBody({
                    //         req_id: gridRowId,
                    //         gridType: gridType,
                    //         lang: res.payload as "CZ" | "EN"
                    //     }))
                    // })
                })
        } else if (gridType === 'Properties' && rowReqId && gridRowId === false) {
            dispatch(GetPropertiesContactLang(rowReqId))
                .then((res) => {
                    dispatch(GetPropertiesEmailBody({req_id: rowReqId, gridType: gridType, lang: res.payload as "CZ" | "EN"}))
                    // .then(() => {
                    //     dispatch(GetPropertiesEmailBody({req_id: rowReqId, gridType: gridType, lang: res.payload as "CZ" | "EN"}))
                    // })
                })
        }
        else {
            dispatch(GetPropertiesEmailBody({
                req_id: 0,
                gridType: 'All Properties/Upcoming',
                lang: contactLang
            }))
        }


    }, [dispatch, gridType, rowReqId, contactLang, gridRowId])
    const allPropertiesCheckedReqs = useAppSelector(brokerageReqCheckedReqs)


    const onDeletePropertyFromModal = (propertyId: number, contactId?: number) => {
        dispatch(deletePropertyEmail(propertyId))
        dispatch(onDeleteCheckedPropertyFromOffer(propertyId))
        if(gridType === 'All Properties/Upcoming'){
            dispatch(deleteAllPropertiesPropertyEmail({propId: propertyId, contactId: contactId, gridType: gridType}))
            dispatch(onDeleteAllPropertiesCheckedPropertyFromOffer({propId: propertyId, contactId: contactId, gridType: gridType}))
        }
        else if(gridType === 'All Properties/REQ'){
            dispatch(deleteAllPropertiesPropertyEmail({propId: propertyId, gridType: gridType}))
            dispatch(onDeleteAllPropertiesCheckedPropertyFromOffer({propId: propertyId, gridType: gridType}))
        }
        if (reqGridDomain === 'OR') {
            dispatch(onRemoveSurfacesFromOffer(propertyId))
        }
    }
    const onDeleteBrokerageCheckedReq = (reqId: number) => {

        dispatch(onSetCurrentReq({reqId: reqId, isChecked: false}))
    }

    const onCloseOfferModal = () => {
        if(gridType === 'All Properties/Upcoming'){
            // dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
            // dispatch(onChangeModalActivityFormField({value: '', inputName: 'Organizations'}))
            dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
            dispatch(clearContactsPropertiesForEmail([]))
            dispatch(onChangeWindowModesMode({modeName: 'Email', mode: 'Closed'}))
            dispatch(onClearOwnerContacts())
            setEmailBodyTextReplaced('')
            dispatch(onSetPropertiesMessageBody(''))
        }
        else {
            dispatch(clearPropertiesForEmail([]))
            dispatch(onChangeReqPropsWindowModesMode({modeName: 'Email', mode: 'Closed'}))
            dispatch(clearSurfacesForEmail([]))
            dispatch(onSetAllSurfaceClosed())
            dispatch(onClearCheckedProperties())
            dispatch(onClearCheckedSurfaces())
            dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
            setEmailBodyTextReplaced('')
            dispatch(onSetPropertiesMessageBody(''))
            dispatch(onClearOwnerContacts())
            // dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
            // dispatch(onChangeModalActivityFormField({value: '', inputName: 'Organizations'}))
        }

    }

    const mergedSurfaces = surfacesForEmail.reduce((acc: any, obj: any) => {
        const matchingObj = emailLinkText;
        const newObj = {...matchingObj};
        if (matchingObj) {
            acc.push(Object.assign({}, obj, newObj));
        } else {
            acc.push(obj);
        }
        return acc;
    }, []);

    useEffect(() => {
        if (gridType === 'All Properties/REQ') {
            setEmailBodyTextReplaced('')
            dispatch(onSetPropertiesMessageBody(''))
        } else {
            setEmailBodyTextReplaced('')
            dispatch(onSetPropertiesMessageBody(''))
        }
    }, [gridType, dispatch])


    const mergedProperties = propertiesForEmail.filter((obj: any, index: any, self: any) =>
        index === self.findIndex((o: any) => o.propertyId === obj.propertyId)
    ).reduce((acc: any, obj: any) => {
        const matchingObj = emailLinkText;
        const newObj = {...matchingObj};
        if (matchingObj) {
            acc.push(Object.assign({}, obj, newObj));
        } else {
            acc.push(obj);
        }
        return acc;
    }, []);


    // const mergedBrokerageReqs

    //creating inputs between the properties and updating their values
    const inputElements = document.querySelectorAll('input');
    const inputIds = Array.from(inputElements).map(input => input.id);

    for (let index = 0; index < inputIds.length; index++) {
        const inputElement = document.getElementById(inputIds[index]);

        if (inputElement) {
            inputElement.addEventListener('keydown', (event) => {
                if (event.keyCode === 13) {
                    event.preventDefault();
                    const {selectionStart, selectionEnd, value}: any = inputElement;
                    // @ts-ignore
                    inputElement.value = value.substring(0, selectionStart) + '\n' + value.substring(selectionEnd);
                    // @ts-ignore
                    inputElement.selectionStart = inputElement.selectionEnd = selectionStart + 1;
                }
            });

            inputElement.addEventListener('blur', function () {
                // @ts-ignore
                const value = this.value;
                // @ts-ignore
                const propertyType = this.name;

                if (propertyType === 'property') {
                    const matchingProperty = propertiesForEmail.find((property: any) => property.propertyId === Number(inputIds[index]));
                    if (matchingProperty) {
                        dispatch(onSetEmailLinksAddText({
                            prop_id: matchingProperty.propertyId,
                            addText: value,
                            gridType: gridType
                        }));
                    }
                } else {
                    const matchingSurface = surfacesForEmail.find((surface: any) => Number(surface.surfaceId) === Number(inputIds[index]));
                    if (matchingSurface) {
                        dispatch(onSetSurfaceEmailLinksAddText({surfaceId: matchingSurface.surfaceId, addText: value}));
                    }
                }
            });
        }
    }

    let emailHyperlinks = mergedProperties.map((p: any, index: number) =>
        p?.photo
            ?
            `
<div key={index} data-blocked-edit="true">
    
  <div data-blocked-edit="true" style="margin: 0 0 10px 0; border-radius: 12px; background-color: #f0f0f6; width: 475px; font-family: 'Inter', sans-serif">
            <a data-blocked-edit="true" style="position: relative; text-decoration: none; color: #1C1C1C; padding: 4px 16px 4px 4px; display: flex;" href="${propertiesLinkStart}/#/p/${gridType === 'Properties' ? gridRowId : currentReqId}-${p.propertyId}-${contactLang}-${emailDomain.message}">
                <div style="display: flex; width: 150px; margin-right: 15px; " >
                    <img src='data:image/png;base64, ${p.photo}' alt="property-photo" style="height: 100%; width: 150px; border-radius: 10px; margin: 0; object-fit: cover">
                </div>
                 <div style="width: 290px;">
                    <p style="margin: 10px 0 4px 0; font-size: 16px; line-height: 20px;">${p.propertyAddress ? `${p.propertyAddress} ${p.propertyId ? `- ${p.propertyId}` : ''} ${p.short_description ? `- ${p.short_description}` : ''}` : ''}</p>
                   <p style="margin: 0; height: 110px; overflow: hidden; text-overflow: ellipsis;font-size: 14px; line-height: 18px;" >${p.description ? p.description : ''}</p>
                </div>
                 <span style="display:block; margin-left: 25px; margin-top: auto; margin-bottom: auto;">
                    <img src='${shadowArrow}' alt="arrow">
                </span> 
            </a> 
        </div>
        <input style="border: none; width: 100%; outline: none; margin: 0 0 10px 0" name="property" id='${p.propertyId}' value='${p.addText}'/>
</div>

      `
            :
            `
<div key={index} data-blocked-edit="true">

<div data-blocked-edit="true" style="margin: 0 0 10px 0; border-radius: 12px; background-color: #f0f0f6; width: 475px; font-family: 'Inter', sans-serif">
            <a data-blocked-edit="true" style="text-decoration: none; color: #1C1C1C; position: relative; padding: 4px 16px 4px 4px; display: flex;"  href="${propertiesLinkStart}/#/p/${gridType === 'Properties' ? gridRowId : currentReqId}-${p.propertyId}-${contactLang}-${emailDomain.message}">
               <div style="display: flex;  width: 150px; margin-right: 15px;" >
                    <img src='${houseImg}' alt="property-photo" style="height: 100%; width: 150px; border-radius: 10px; margin: 0; object-fit: cover">
                </div>
                 <div style="width: 290px;">
                      <p style="margin: 10px 0 4px 0; font-size: 16px; line-height: 20px;">${p.propertyAddress ? `${p.propertyAddress} ${p.propertyId ? `- ${p.propertyId}` : ''} ${p.short_description ? `- ${p.short_description}` : ''}` : ''}</p>
                    <p style="margin: 0; height: 110px; overflow: hidden; text-overflow: ellipsis;font-size: 14px; line-height: 18px;">${p.description ? p.description : ''}</p>
                </div>
                 <span style="display:block; margin-left: 25px; margin-top: auto; margin-bottom: auto;">
                    <img src='${shadowArrow}' alt="arrow">
                </span> 
            </a> 
        </div>
         <input style="border: none; width: 100%; outline: none; margin: 0 0 10px 0" name="property" id='${p.propertyId}' value='${p.addText}'/>
</div>

        `
    )

    let emailSurfacesHyperlinks = mergedSurfaces.map((p: any, index: number) =>
        p?.photo
            ?
            `
<div key={index} data-blocked-edit="true">

 <div data-blocked-edit="true" style="margin: 0 0 10px 0; border-radius: 12px; background-color: #f0f0f6; width: 475px; font-family: 'Inter', sans-serif">
            <a data-blocked-edit="true" style="position: relative; text-decoration: none; color: #1C1C1C; padding: 4px 16px 4px 4px; display: flex;" href="${propertiesLinkStart}/#/s/${gridRowId}-${p.propertyId}-${p.surfaceId}-${contactLang}-${emailDomain.message}">
                <div style="display: flex;   width: 150px; margin-right: 15px;" >
                    <img src='data:image/png;base64, ${p.photo}' alt="property-photo" style="height: 100%; width: 150px; border-radius: 10px; margin: 0; object-fit: cover">
                </div>
                 <div style="width: 290px;">
                    <p style="margin: 10px 0 4px 0; font-size: 16px; line-height: 20px;">${p.propertyAddress} ${p.propertyId ? `- ${p.propertyId}` : ''} ${p.surfaceId ? `- ${p.surfaceId}` : ''} ${p.short_description ? `- ${p.short_description}` : ''}</p>
                   <p style="margin: 0; height: 110px; overflow: hidden; text-overflow: ellipsis;font-size: 14px; line-height: 18px;" >${p.description ? p.description : ''}</p>
                </div>
                 <span style="display:block; margin-left: 25px; margin-top: auto; margin-bottom: auto;">
                    <img src='${shadowArrow}' alt="arrow">
                </span> 
            </a> 
        </div>
        <input style="border: none; width: 100%; outline: none; margin: 0 0 10px 0" name="surface" id='${p.surfaceId}' value='${p.addText}'/>
</div>

      `
            :
            `
<div key={index} data-blocked-edit="true">

<div data-blocked-edit="true" style="margin: 0 0 10px 0; border-radius: 12px; background-color: #f0f0f6; width: 475px; font-family: 'Inter', sans-serif">
            <a data-blocked-edit="true" style="text-decoration: none; color: #1C1C1C; position: relative; padding: 4px 16px 4px 4px; display: flex;"  href="${propertiesLinkStart}/#/s/${gridRowId}-${p.propertyId}-${p.surfaceId}-${contactLang}-${emailDomain.message}">
               <div style="display: flex;  width: 150px; margin-right: 15px;" >
                    <img src='${houseImg}' alt="property-photo" style="height: 100%; width: 150px; border-radius: 10px; margin: 0; object-fit: cover">
                </div>
                 <div style="width: 290px;">
                     <p style="margin: 10px 0 4px 0; font-size: 16px; line-height: 20px;">${p.propertyAddress} ${p.propertyId ? `- ${p.propertyId}` : ''} ${p.surfaceId ? `- ${p.surfaceId}` : ''} ${p.short_description ? `- ${p.short_description}` : ''}</p>
                    <p style="margin: 0; height: 110px; overflow: hidden; text-overflow: ellipsis;font-size: 14px; line-height: 18px;">${p.description ? p.description : ''}</p>
                </div>
                 <span style="display:block; margin-left: 25px; margin-top: auto; margin-bottom: auto;">
                    <img src='${shadowArrow}' alt="arrow">
                </span> 
            </a> 
        </div>
         <input style="border: none; width: 100%; outline: none; margin: 0 0 10px 0" name="surface" id='${p.surfaceId}' value='${p.addText}'/>
</div>

        `
    )

    const onDeleteSurfaceFromModal = (surfaceId: number) => {
        dispatch(deleteSurfaceForEmail(surfaceId))
        dispatch(onDeleteCheckedSurfacesFromOffer(Number(surfaceId)))
    }

    const emailHyperlinksTextArea = propertiesForEmail.map((p: any) => `[${p.propertyId} - ${p.propertyAddress} ${p.propertyTitle ? `- ${p.propertyTitle}` : ''}]`)

    const resultEmailHyperlinks = emailHyperlinks.map((item: any) => item).join("\n");
    const resultEmailProperties = mergedProperties.map((item: any, index: number) => index < mergedProperties.length - 1 ? `[${item.propertyAddress}],` : `[${item.propertyAddress}] `).join("\n");
    const resultEmailSurfaces = emailSurfacesHyperlinks.map((item: any) => item).join("\n");
    let messageBody = gridType !== 'All Properties/REQ'
        ? `${emailGreeting?.replace(',', '')}, \n ${emailBodyText.message}`
        :
        gridType === 'All Properties/REQ' && allPropertiesCheckedReqs.length > 1
            ?
            `Greeting. <br><br>${emailBodyText.message}`
            :
            `${emailGreeting?.replace(',', '')}, \n ${emailBodyText.message}`

    const offerRegex = /#agent_address([\s\S]*?)(?=#agent_email|$)/g;
    const offerRegex1 = /#offer/g;


    const extractedOffer = (emailBodyText.message?.match(offerRegex) || []).join('').trim();
    const extractedPTags = (emailBodyText.message?.match(offerRegex1) || []).join('').trim();


// Combine the extracted elements into one string
    const combinedString = `<br>` + extractedPTags + '\n\n' + extractedOffer;

    useEffect(() => {

        function replaceRowWithPTag(inputString: string) {
            const lines = inputString?.split('\n');

            const modifiedLines = [];
            let currentParagraph = '';
            let isEven = false; // Flag to track even/odd paragraphs
            if (lines) {
                for (const line of lines) {
                    const regex = /[A-Z]{4,}/g;
                    const matches = line.match(regex);

                    if (matches && matches.some(match => match.length >= 4)) {
                        // Append the entire line to the current paragraph
                        currentParagraph += line + '\n';
                    } else {
                        // If the current paragraph is not empty, wrap it in a <p> tag with styles
                        if (currentParagraph !== '') {

                            let coloredParagraph = currentParagraph.split(' ')
                            coloredParagraph = coloredParagraph?.map((str: any, index: any) => (
                                `<span key=${index} style="color: ${index % 2 === 0 ? 'black' : 'red'}; font-family: Inter, sans-serif;font-weight: 700;font-size: 12pt; margin-bottom: 0; margin-right: 3px">${str}</span> `
                            ));


                            modifiedLines.push(`<span style="display:flex;">${coloredParagraph.join('')}</span>`);
                            currentParagraph = ''; // Reset the current paragraph
                            isEven = !isEven; // Toggle the even/odd flag
                        }

                        // Keep the original line
                        modifiedLines.push(line);
                    }
                }
            }


            // If the last paragraph is not empty, wrap it in a <p> tag with styles
            if (currentParagraph !== '') {
                const paragraphStyle = isEven ? 'color: black;' : 'color: red;';
                modifiedLines.push(`<span style="${paragraphStyle}">${currentParagraph.trim()}</span>`);
            }

            const modifiedString = modifiedLines.join('\n');

            return modifiedString;
        }

        const replacedString = replaceRowWithPTag(agentAddress);
        if (gridType === 'Properties') {

            if (emailBodyText.message !== '' && emailBodyTextReplaced === '') {
                let agentAddressEmail: any
                agentAddressEmail = replacedString ? replacedString?.replace(/\r\n/g, '<br/>') : userData.email
                setEmailBodyTextReplaced(emailBodyText.message !== null ? messageBody
                        .replace(/<p[^>]*>[\s\S]*?<\/p>/g, '')
                        .replace('S pozdravem,', `S pozdravem`)
                        .replace('#agent_email', ` `)
                        .replace('#offer', `<br><div id="offer">${resultEmailHyperlinks} ${resultEmailSurfaces}</div>`)
                        .replace('\n', '<br/>')
                        .replace(' \n', '<br/>')
                        .replace(' \n\n', '<br/>')
                        .replace(',', '<br/>')
                        .replace('S pozdravem', `<br><br/>S pozdravem`)
                        .replace('Thank you', `<br><br/>Thank you<br/>`)
                        .replace('#agent_address', `<br/>${agentAddressEmail ? agentAddressEmail : ''}<br><br/>`)
                    :
                    ''
                )
            }
        } else if (gridType === 'All Properties/Upcoming') {
            if (emailBodyText.message !== '' && emailBodyTextReplaced === '') {
                let agentAddressEmail: any
                agentAddressEmail = replacedString ? replacedString?.replace(/\r\n/g, '<br/>') : userData.email
                // @ts-ignore
                setEmailBodyTextReplaced(emailBodyText.message !== null ? combinedString
                        .replace(/<p[^>]*>[\s\S]*?<\/p>/g, '')
                        .replace('#agent_address', `<br/>${agentAddressEmail ? agentAddressEmail : ''}<br><br/>`)
                        .replace('#offer', `<br><div id="offer">Properties with numbers: <br/> ${resultEmailProperties} ready to be put on the market.</div>`)
                        .replace(/\\\\n\\\\n/g, '')
                        .replace('\\n\\n', '')
                        .replace('\n\n', '')
                        .replace(' \n\n', '')
                        .replace(/\\n/g, '')
                    :
                    ''
                )
            }
        }

    }, [emailBodyText, userData.email, messageBody, emailBodyTextReplaced, emailHyperlinksTextArea, agentAddress, resultEmailHyperlinks, resultEmailSurfaces])

    useEffect(() => {
        function replaceRowWithPTag(inputString: string) {
            const lines = inputString?.split('\n');

            const modifiedLines = [];
            let currentParagraph = '';
            let isEven = false; // Flag to track even/odd paragraphs
            if (lines) {
                for (const line of lines) {
                    const regex = /[A-Z]{4,}/g;
                    const matches = line.match(regex);

                    if (matches && matches.some(match => match.length >= 4)) {
                        // Append the entire line to the current paragraph
                        currentParagraph += line + '\n';
                    } else {
                        // If the current paragraph is not empty, wrap it in a <p> tag with styles
                        if (currentParagraph !== '') {

                            let coloredParagraph = currentParagraph.split(' ')
                            coloredParagraph = coloredParagraph?.map((str: any, index: any) => (
                                `<span key=${index} style="color: ${index % 2 === 0 ? 'black' : 'red'}; font-family: Inter, sans-serif;font-weight: 700;font-size: 12pt; margin-bottom: 0; margin-right: 3px">${str}</span> `
                            ));


                            modifiedLines.push(`<span style="display:flex;">${coloredParagraph.join('')}</span>`);
                            currentParagraph = ''; // Reset the current paragraph
                            isEven = !isEven; // Toggle the even/odd flag
                        }

                        // Keep the original line
                        modifiedLines.push(line);
                    }
                }
            }


            // If the last paragraph is not empty, wrap it in a <p> tag with styles
            if (currentParagraph !== '') {
                const paragraphStyle = isEven ? 'color: black;' : 'color: red;';
                modifiedLines.push(`<span style="${paragraphStyle}">${currentParagraph.trim()}</span>`);
            }

            const modifiedString = modifiedLines.join('\n');

            return modifiedString;
        }

        const replacedString = replaceRowWithPTag(agentAddress);
        if (gridType === 'All Properties/REQ') {
            let agentAddressEmail: any
            agentAddressEmail = replacedString ? replacedString?.replace(/\r\n/g, '<br/>') : userData.email
            if (messageOfferBody === '') {
                setEmailBodyTextReplaced(emailBodyText && emailBodyText.message && emailBodyText.message !== null ? messageBody
                        .replace(/<p[^>]*>[\s\S]*?<\/p>/g, '')
                        .replace('S pozdravem,', `S pozdravem`)
                        .replace('#agent_email', ` `)
                        .replace('#offer', `<br><div id="offer">${emailHyperlinks}</div>`)
                        .replace('\n', '<br/>')
                        .replace(' \n', '<br/>')
                        .replace(' \n\n', '<br/>')
                        .replace(',', '<br/>')
                        .replace('S pozdravem', `<br><br/>S pozdravem`)
                        .replace('Thank you', `<br><br/>Thank you<br/>`)
                        .replace('#agent_address', `<br/>${agentAddressEmail ? agentAddressEmail : ''}<br><br/>`)
                    :
                    ''
                )
            }
        }
    }, [emailBodyText, userData.email, messageBody, emailBodyTextReplaced, agentAddress, gridType, emailHyperlinks, messageOfferBody])

    useEffect(() => {
        if (gridType === 'Properties') {
            setEmailBodyTextReplaced(messageOfferBody.replace(/<div id="offer">[\s\S]*<\/div>/g, `<br><div id="offer">${resultEmailHyperlinks} ${resultEmailSurfaces}</div>`))
        } else if (gridType === 'All Properties/Upcoming') {
            setEmailBodyTextReplaced(messageOfferBody
                .replace(/<div id="offer">[\s\S]*<\/div>/g, `<br><div id="offer">Properties with numbers: <br/> ${resultEmailProperties} ready to be put on the market.</div>`)
                .replace(/\\\\n\\\\n/g, '')
                .replace('\\n\\n', '')
                .replace('\n\n', '')
                .replace(' \n\n', '')
                .replace(/\\n/g, ''))
        }
    }, [resultEmailSurfaces, resultEmailHyperlinks, resultEmailProperties, gridType]);


    function removeTags(str: any) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        return str.replace(/<[^>]*>/g, '');
    }

    const onFinish = (values: any) => {
        const p_prop_list = mergedProperties.map((obj: any) => obj.propertyId).join(', ');
        const p_surf_list = mergedSurfaces.map((obj: any) => obj.surfaceId).join(', ');
        const mailBody = messageOfferBody !== '' ? messageOfferBody.replace(/<div id="offer">[\s\S]*<\/div>/g, `<div id="offer">${resultEmailHyperlinks} ${resultEmailSurfaces}</div>`) : emailBodyTextReplaced;
        const mailBodyForUpcomingPropertiesOffer = messageOfferBody !== '' ? messageOfferBody.replace(/<div id="offer">[\s\S]*<\/div>/g, `<div id="offer">Properties with numbers: <br/> ${resultEmailProperties} ready to be put on the market.</div>`) : emailBodyTextReplaced;
        const arrowSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAIAAABLixI0AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAtSURBVEhLY/zw4RsDlQATlKYGGDWLNDBqFmlg1CzSwKhZpIFRs0gDw98sBgYAyMkDCHMFG3MAAAAASUVORK5CYII='
        const parser = new DOMParser();
        const doc = parser.parseFromString(mailBody, "text/html");
        const images = doc.querySelectorAll("img");
        const cleanedString = removeTags(mailBody);
        const cleanedStringForUpcomingPropertiesOffer = removeTags(mailBodyForUpcomingPropertiesOffer);
        images.forEach((img, index: number) => {
            if (img.getAttribute('src') === arrowSrc) {
                img.setAttribute("src", `cid:array-id-${index}`);
            } else {
                img.setAttribute("src", `cid:image-id-${index}`);
            }
        })
        const mailBodyTo = doc.documentElement.outerHTML
        if (gridType === 'All Properties/Upcoming'){
            if(!offerToContacts.filter((c: any) => c.value !== undefined).length){
                offerToContacts = [...ownerContacts]
            }
        }
        const req = {
            p_agent_ref: Number(userData.user_ref),
            p_domain_cd: emailDomain.message,
            p_lang_cd: contactLang,
            p_mail_bcc_list: offerBCCContacts?.map((item: any) => item.value).join(","),
            p_mail_body: mailBodyTo,
            p_mail_cc_list: offerCCContacts?.map((item: any) => item.value).join(","),
            p_mail_subject: values?.subjects,
            p_mail_to_list: offerToContacts?.map((item: any) => item.value).join(","),
            p_prop_list: p_prop_list,
            p_req_ref: gridType === 'Properties' ? gridRowId : currentReqId,
            p_body_text: cleanedString.replace(/\s+(?=([^"]*"[^"]*")*[^"]*$)/g, ' ').trim(),
            p_surf_list: p_surf_list,
            photo_type: 'Property'
        }
        const reqForWithoutReqGrid = {
            p_agent_ref: Number(userData.user_ref),
            p_domain_cd: emailDomain.message,
            grid_type: "PROP_CONTACT",
            is_create_offer: false,
            p_lang_cd: contactLang,
            p_mail_bcc_list: offerBCCContacts?.map((item: any) => item.value).join(","),
            p_mail_body: mailBodyForUpcomingPropertiesOffer,
            p_mail_cc_list: offerCCContacts?.map((item: any) => item.value).join(","),
            p_mail_subject: values?.subjects,
            p_mail_to_list: offerToContacts?.map((item: any) => item.value).join(","),
            p_prop_list: p_prop_list,
            p_req_ref: null,
            p_body_text: cleanedStringForUpcomingPropertiesOffer.replace(/\s+(?=([^"]*"[^"]*")*[^"]*$)/g, ' ').trim(),
            p_surf_list: p_surf_list,
            photo_type: 'Property'
        }
        if(gridType !== 'All Properties/REQ'){
            if (!ownerContacts.length && !offerToContacts.filter((c: any) => c.value !== undefined).length) {
                dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: true}))
                // @ts-ignore
                divRef.current.scrollIntoView({behavior: 'smooth'});
            }
            else {
                dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
                dispatch(onSetFilteredPropertiesOfferToContacts([]))
                dispatch(onSetFilteredPropertiesOfferCCContacts([]))
                dispatch(onSetFilteredPropertiesOfferBCCContacts([]))
                dispatch(onSetIsSendBtnDisabled(true))
                if (gridType === 'All Properties/Upcoming') {
                    dispatch(CreatePropertiesEmailOffer([reqForWithoutReqGrid]))
                        .then(() => {
                            setIsLoading(false)
                            // dispatch(onChangeModalActivityFormField({value: '', inputName: 'Organizations'}))
                            // dispatch(onChangeModalActivityFormField({value: '', inputName: 'Contacts'}))
                            // dispatch(onClearActivitiesContacts())
                            dispatch(clearPropertiesForEmail([]))
                            dispatch(onSetCurrentWindow(null))
                            dispatch(onChangeWindowModesMode({modeName: 'Email', mode: 'Closed'}))
                        })
                } else {
                    dispatch(CreatePropertiesEmailOffer([req]))
                        .then(() => {
                            setIsLoading(false)
                        })
                }
                // console.log(req)
                dispatch(onClearCheckedProperties())
                dispatch(onClearCheckedSurfaces())
                // dispatch(onSetEmailSubject(previousEmailSubject))
                onCloseOfferModal()
            }
        }
        else {
            dispatch(onSetFilteredPropertiesOfferToContacts([]))
            dispatch(onSetFilteredPropertiesOfferCCContacts([]))
            dispatch(onSetFilteredPropertiesOfferBCCContacts([]))
            // dispatch(onSetIsSendBtnDisabled(true))
            if (offerType === 'Letter to the owner') {
                // dispatch(CreateBrokerageReqsLetterForOwner(req))
            } else {
                if(allPropertiesCheckedReqs.some((r:BrokerageCheckedReq) => r.isEmptyContactTo)){
                    dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: true}))
                    // @ts-ignore
                    divRef.current.scrollIntoView({behavior: 'smooth'});

                }
                else{
                    setIsLoading(true)
                    dispatch(onSetIsSendBtnDisabled(true))
                    dispatch(onSetPropertiesOfferFormError({fieldType: 'To', isError: false}))
                    const withReqData = allPropertiesCheckedReqs.map((r: BrokerageCheckedReq) => {

                        return (
                            {
                                p_agent_ref: Number(userData.user_ref),
                                p_domain_cd: r.DOMAIN!,
                                grid_type: "PROP_WITH_REQ_DATA",
                                is_create_offer: true,
                                p_lang_cd: contactLang,
                                p_mail_bcc_list: offerBCCContacts?.map((item: any) => item.value).join(","),
                                p_mail_body:
                                    allPropertiesCheckedReqs.length > 1
                                        ? mailBodyTo
                                            .replace('Greeting', `${r.emailGreeting}`)
                                            .replace(
                                                /<div id="offer">[\s\S]*<\/div>/g,
                                                `<br><div id="offer">
                                                    <div key={1} data-blocked-edit="true">
                                                        <div data-blocked-edit="true" style="margin: 0 0 10px 0; border-radius: 12px; background-color: #f0f0f6; width: 475px; font-family: 'Inter', sans-serif">
                                                            <a data-blocked-edit="true" style="text-decoration: none; color: #1C1C1C; position: relative; padding: 4px 16px 4px 4px; display: flex;"  href="${propertiesLinkStart}/#/p/${r.REQ_ID}-${selectedBrokerageProperty.PROP_ID}-${contactLang}-${r.DOMAIN!}">
                                                                <div style="display: flex;  width: 150px; margin-right: 15px;" >
                                                                    <img src='cid:image-id-0' alt="property-photo" style="height: 100%; width: 150px; border-radius: 10px; margin: 0; object-fit: cover">
                                                                </div>
                                                                <div style="width: 290px;">
                                                                    <p style="margin: 10px 0 4px 0; font-size: 16px; line-height: 20px;">${selectedBrokerageProperty.ADDR_STREET ? `${selectedBrokerageProperty.ADDR_STREET} ${selectedBrokerageProperty.PROP_ID ? `- ${selectedBrokerageProperty.PROP_ID}` : ''} ${selectedBrokerageProperty.DESCRIPTION ? `- ${selectedBrokerageProperty.DESCRIPTION}` : ''}` : ''}</p>
                                                                    <p style="margin: 0; height: 110px; overflow: hidden; text-overflow: ellipsis;font-size: 14px; line-height: 18px;">${selectedBrokerageProperty.DESCRIPTION ? selectedBrokerageProperty.DESCRIPTION : ''}</p>
                                                                </div>
                                                                <span style="display:block; margin-left: 25px; margin-top: auto; margin-bottom: auto;">
                                                                    <img src='cid:array-id-1' alt="arrow">
                                                                </span> 
                                                            </a>
                                                        </div>
                                                            </div>
                                                        </div>`)
                                        : mailBodyTo
                                            .replace(
                                                /<div id="offer">[\s\S]*<\/div>/g,
                                                `<br><div id="offer">
                                                    <div key={1} data-blocked-edit="true">
                                                        <div data-blocked-edit="true" style="margin: 0 0 10px 0; border-radius: 12px; background-color: #f0f0f6; width: 475px; font-family: 'Inter', sans-serif">
                                                            <a data-blocked-edit="true" style="text-decoration: none; color: #1C1C1C; position: relative; padding: 4px 16px 4px 4px; display: flex;"  href="${propertiesLinkStart}/#/p/${r.REQ_ID}-${selectedBrokerageProperty.PROP_ID}-${contactLang}-${r.DOMAIN!}">
                                                                <div style="display: flex;  width: 150px; margin-right: 15px;" >
                                                                    <img src='cid:image-id-0' alt="property-photo" style="height: 100%; width: 150px; border-radius: 10px; margin: 0; object-fit: cover">
                                                                </div>
                                                                <div style="width: 290px;">
                                                                    <p style="margin: 10px 0 4px 0; font-size: 16px; line-height: 20px;">${selectedBrokerageProperty.ADDR_STREET ? `${selectedBrokerageProperty.ADDR_STREET} ${selectedBrokerageProperty.PROP_ID ? `- ${selectedBrokerageProperty.PROP_ID}` : ''} ${selectedBrokerageProperty.DESCRIPTION ? `- ${selectedBrokerageProperty.DESCRIPTION}` : ''}` : ''}</p>
                                                                    <p style="margin: 0; height: 110px; overflow: hidden; text-overflow: ellipsis;font-size: 14px; line-height: 18px;">${selectedBrokerageProperty.DESCRIPTION ? selectedBrokerageProperty.DESCRIPTION : ''}</p>
                                                                </div>
                                                                <span style="display:block; margin-left: 25px; margin-top: auto; margin-bottom: auto;">
                                                                    <img src='cid:array-id-1' alt="arrow">
                                                                </span> 
                                                            </a>
                                                        </div>
                                                            </div>
                                                        </div>`),
                                p_mail_cc_list: offerCCContacts?.map((item: any) => item.value).join(","),
                                p_mail_subject: values?.subjects,
                                p_mail_to_list: r.contacts?.flat(1).map((item: any) => item.value).join(","),
                                p_prop_list: p_prop_list,
                                p_req_ref: r.REQ_ID!,
                                p_body_text: cleanedString.replace('Greeting', `${r.emailGreeting}`).replace(/\s+(?=([^"]*"[^"]*")*[^"]*$)/g, ' ').trim(),
                                p_surf_list: p_surf_list,
                                photo_type: 'Property'
                            }
                        )
                    })
                    dispatch(CreatePropertiesEmailOffer(withReqData))
                        .then(() => {
                            setIsLoading(false)
                        })
                    // // console.log(req)
                    dispatch(onClearCheckedProperties())
                    dispatch(onClearCheckedSurfaces())
                    // dispatch(onSetEmailSubject(previousEmailSubject))
                    onCloseOfferModal()
                }

            }



        }
    }


    useEffect(() => {
        // @ts-ignore
        form.setFieldsValue({subjects: emailSubject.message})
    }, [emailSubject])
    const isOfferOpened = (gridType !== 'All Properties/Upcoming' && gridType !== 'Properties') ? (propertiesForEmail.length || surfacesForEmail.length || brokerageReqCheckedProperties.length || allPropertiesCheckedReqs.length) && (!isActivityModalOpened) : isModalOpened && (!isActivityModalOpened)


    const unHidePropContactOfferModal = () => {
        setEmailBodyTextReplaced(messageOfferBody)
        if (unHideModal) {
            unHideModal()
        }
    }

    return (
        isOfferOpened
            ?
            <Form
                name='offerForm'
                onFinish={onFinish}
                autoComplete='off'
                validateTrigger='onBlur'
                className={`offerForm ${s.messageInner}`}
                form={form}
                initialValues={{
                    subjects: `${emailSubject.message !== null ? emailSubject.message : ''}`,
                    user: [defaultContact[0]?.PE_EMAIL]
                }}
                style={{
                    height: hiddenModal ? '40px' : 'auto',
                    position: hiddenModal ? 'fixed' : currentScreenWidth! > 768 ? 'relative' : 'absolute',
                    bottom: '0px',
                    right: hiddenModal ? currentScreenWidth! > 768 ? '32px' : '0' : '0',
                    top: currentScreenWidth! > 768 ? 'initial' : hiddenModal ? 'initial' : '152px',
                    backgroundColor: hiddenModal ? currentScreenWidth! > 768 ? '#fff' : 'transparent' : '#fff',
                    zIndex: '200'
                }}
            >
                <div
                    style={{
                        height: gridContentHeight + 195,
                        minHeight: 'auto',
                        overflowY: hiddenModal ? 'hidden' : 'scroll',
                        overflowX: 'hidden',
                        borderRadius: hiddenModal ? '8px 8px 0 0' : '8px 0 0 0',
                        minWidth: hiddenModal ? '260px' : currentScreenWidth! > 768 ? '520px' : '100%',
                        maxWidth: hiddenModal ? '260px' : currentScreenWidth! > 768 ? '520px' : '100%'
                    }}
                >
                    <div>
                        <div
                            className={s.messageInner__header}
                            style={{
                                padding: hiddenModal ? '12px 12px 11px 16px' : '23px 12px 23px 16px',
                                borderRadius: hiddenModal ? '8px 8px 0 0' : '8px 0 0 0',
                                height: isBrokerage && !hiddenModal ? '70px' : 'auto'
                            }}
                        >
                            <p
                                style={{
                                    fontSize: hiddenModal ? '14px' : '18px'
                                }}
                            >{hiddenModal ? 'New Message' : gridType === 'All Properties/Upcoming' ? 'CONFIRMATION EMAIL' : 'OFFER SUBSCRIPTION'}</p>
                            <div className={s.messageInner__buttons}>
                                <button
                                    type={'button'}
                                    onClick={onHideModal}
                                >
                                    {hiddenModal
                                        ?
                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 4.44444V0H5.55556L7.38333 1.82778L1.82778 7.38333L0 5.55556V10H4.44444L2.61667 8.17222L8.17222 2.61667L10 4.44444Z"
                                                fill="white" fillOpacity="0.6"/>
                                        </svg>
                                        :
                                        <svg width="11" height="3" viewBox="0 0 11 3" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.75 0.208496H10.75V2.2085H0.75V0.208496Z"
                                                  fill="white" fillOpacity="0.6"/>
                                        </svg>
                                    }

                                </button>
                                <button
                                    type={'button'}
                                    onClick={onCloseOfferModal}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_1387_34908)">
                                            <path
                                                d="M4.66667 3L3 4.66667L6.33333 8L3 11.3333L4.66667 13L8 9.66667L11.3333 13L13 11.3333L9.66667 8L13 4.66667L11.3333 3L8 6.33333L4.66667 3Z"
                                                fill="white" fillOpacity="0.6"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1387_34908">
                                                <rect width="10" height="10" fill="white"
                                                      transform="matrix(-1 0 0 -1 13 13)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className={s.messageInner__main}
                             ref={divRef}>
                            {
                                gridType === 'All Properties/REQ'
                                    ?
                                    allPropertiesCheckedReqs.length <= 1
                                        ?

                                        allPropertiesCheckedReqs.map((r: BrokerageCheckedReq) => {
                                            return(
                                                <>
                                                    <div
                                                        className={gridType === 'All Properties/REQ' ? `${s.messageInner__item}` : `${s.messageInner__item} ${s.messageInner__item_owner}`}
                                                        style={{
                                                            borderBottom: isPropertiesToError && r.isEmptyContactTo ? '1px solid #fb000a' : '1px solid #EDEFF1'
                                                        }}>
                                                        <PropertyListComponent contactType={'to'}
                                                                               gridType={gridType}
                                                                               isBrokerage={isBrokerage}
                                                                               req={r}
                                                        />
                                                    </div>
                                                    <div
                                                        className={`${s.messageInner__item} ${s.messageInner__item_cc}`}
                                                        style={{
                                                            borderBottom: '1px solid #EDEFF1'
                                                        }}
                                                    >
                                                        <PropertyListComponent contactType={'cc'} isBrokerage={isBrokerage}  gridType={gridType}/>
                                                    </div>
                                                    <div
                                                        className={`${s.messageInner__item} ${s.messageInner__item_bcc}`}
                                                        style={{
                                                            borderBottom: '1px solid #EDEFF1'
                                                        }}
                                                    >
                                                        <PropertyListComponent contactType={'bcc'} isBrokerage={isBrokerage}  gridType={gridType}/>
                                                    </div>
                                                    <Form.Item
                                                        name='subjects'
                                                        initialValue={emailSubject.message || emailSubject.message !== null ? emailSubject.message : ''}
                                                        required={true}
                                                    >
                                                        <Input
                                                            defaultValue={emailSubject.message || emailSubject.message !== null ? emailSubject.message : ''}
                                                            placeholder={'Enter subject'} required={true}
                                                        />
                                                    </Form.Item>
                                                </>
                                            )
                                        })


                                        :
                                        <>
                                            {
                                                allPropertiesCheckedReqs.map((r: BrokerageCheckedReq) => {
                                                    return (
                                                        <div className={s.multipleOffer}>
                                                            <div className={s.multipleOffer__header}>
                                                            <span
                                                                className={s.multipleOffer__reqText}>Requirement:</span>
                                                                <div className={s.messageInner__property}>
                                                                    <span>{r.REQ_ID} - {r.CO_NAME}</span>
                                                                    <button
                                                                        onClick={() => onDeleteBrokerageCheckedReq(r.REQ_ID!)}
                                                                        type={'button'}
                                                                    >
                                                                        <svg width="12" height="12"
                                                                             viewBox="0 0 12 12"
                                                                             fill="none"
                                                                             xmlns="http://www.w3.org/2000/svg">
                                                                            <g clipPath="url(#clip0_1387_36685)">
                                                                                <path
                                                                                    d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                                    fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_1387_36685">
                                                                                    <rect width="12" height="12"
                                                                                          fill="white"/>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>

                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={gridType === 'All Properties/REQ' ? `${s.messageInner__item}` : `${s.messageInner__item} ${s.messageInner__item_owner}`}
                                                                style={{
                                                                    borderBottom: isPropertiesToError && r.isEmptyContactTo ? '1px solid #fb000a' : '1px solid #EDEFF1'
                                                                }}>
                                                                <PropertyListComponent contactType={'to'}
                                                                                       gridType={gridType}
                                                                                       isBrokerage={isBrokerage}
                                                                                       req={r}
                                                                />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <Form.Item
                                                name='subjects'
                                                initialValue={emailSubject.message || emailSubject.message !== null ? emailSubject.message : ''}
                                                required={true}
                                            >
                                                <Input
                                                    defaultValue={emailSubject.message || emailSubject.message !== null ? emailSubject.message : ''}
                                                    placeholder={'Enter subject'} required={true}
                                                />
                                            </Form.Item>
                                        </>
                                    :
                                    <>
                                        <div
                                            className={gridType === 'All Properties/Upcoming' ? `${s.messageInner__item} ${s.messageInner__item_to}` : `${s.messageInner__item} ${s.messageInner__item_to}`}
                                            style={{
                                                borderBottom: isPropertiesToError  ? '1px solid #fb000a' : '1px solid #EDEFF1'
                                            }}>
                                            <PropertyListComponent contactType={'to'}
                                                                   gridType={gridType}
                                            />
                                        </div>
                                        <div
                                            className={`${s.messageInner__item} ${s.messageInner__item_cc}`}
                                            style={{
                                                borderBottom: '1px solid #EDEFF1'
                                            }}
                                        >
                                            <PropertyListComponent contactType={'cc'} isBrokerage={isBrokerage} gridType={gridType}/>
                                        </div>
                                        <div
                                            className={`${s.messageInner__item} ${s.messageInner__item_bcc}`}
                                            style={{
                                                borderBottom: '1px solid #EDEFF1'
                                            }}
                                        >
                                            <PropertyListComponent contactType={'bcc'} isBrokerage={isBrokerage} gridType={gridType}/>
                                        </div>
                                        <Form.Item
                                            name='subjects'
                                            initialValue={emailSubject.message || emailSubject.message !== null ? emailSubject.message : ''}
                                            required={true}
                                        >
                                            <Input
                                                defaultValue={emailSubject.message || emailSubject.message !== null ? emailSubject.message : ''}
                                                placeholder={'Enter subject'} required={true}
                                            />
                                        </Form.Item>
                                    </>
                            }






                            <div style={{
                                maxHeight: '450px'
                            }}>
                                {
                                    gridType === 'All Properties/REQ' && allPropertiesCheckedReqs.length
                                    && allPropertiesCheckedReqs.length === 1
                                    &&
                                    <div className={s.messageInner__properties}>
                                        <p>Requirement:</p>
                                        <div className={s.messageInner__propertiesInner}>
                                            {allPropertiesCheckedReqs.map((p: any, index: number) => {
                                                return (
                                                    <div className={s.messageInner__property} key={index}>
                                                        <p>
                                                            <span>{`${p?.REQ_ID ? p?.REQ_ID + ' - ' : ''}${p?.CO_NAME} ${p?.REMARKS ? `(${p?.REMARKS})` : ''}`}</span>
                                                        </p>
                                                        <button
                                                            onClick={() => onDeleteBrokerageCheckedReq(p.REQ_ID)}
                                                            type={'button'}
                                                        >
                                                            <svg width="12" height="12"
                                                                 viewBox="0 0 12 12"
                                                                 fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_1387_36685)">
                                                                    <path
                                                                        d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                        fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_1387_36685">
                                                                        <rect width="12" height="12"
                                                                              fill="white"/>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>

                                                        </button>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                }
                                {propertiesForEmail.length && gridType !== 'All Properties/Upcoming'
                                    ?
                                    <div className={s.messageInner__properties}>
                                        <p>Properties:</p>
                                        <div className={s.messageInner__propertiesInner}>
                                            {propertiesForEmail.filter((obj: any, index: any, self: any) =>
                                                index === self.findIndex((o: any) => o.propertyId === obj.propertyId)
                                            ).map((p: any, index: number) => {
                                                const hyperLink = `${propertiesLinkStart}/#/p/${gridType === 'All Properties/REQ' ? currentReqId : gridRowId}-${p.propertyId}-${contactLang}-${emailDomain.message}`
                                                return (
                                                    <div className={s.messageInner__property} key={index}>
                                                        <a href={hyperLink} target={'_blank'}
                                                           rel="noreferrer">
                                                            <span>{`${gridType === 'All Properties/REQ' ? '' : `${gridRowId} -`} ${p.propertyId} - ${p.propertyAddress} ${p.propertyTitle ? `- ${p.propertyTitle}` : ''}`}</span>
                                                        </a>
                                                        {
                                                            gridType !== 'All Properties/REQ'
                                                            &&
                                                            <button
                                                                onClick={() => onDeletePropertyFromModal(p.propertyId)}
                                                                type={'button'}
                                                            >
                                                                <svg width="12" height="12" viewBox="0 0 12 12"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_1387_36685)">
                                                                        <path
                                                                            d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                            fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_1387_36685">
                                                                            <rect width="12" height="12"
                                                                                  fill="white"/>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>

                                                            </button>
                                                        }

                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    :
                                    propertiesForEmail.length
                                        ?
                                        <div className={s.messageInner__properties}>
                                            <p>Properties:</p>
                                            <div className={s.messageInner__propertiesInner}>
                                                {propertiesForEmail.map((p: any, index: number) => {
                                                    return (
                                                        <div className={s.messageInner__property} key={index}>
                                                            <p>
                                                                <span>{`${gridType === 'All Properties/REQ' ? allPropertiesCurrentReq[0]?.REQ_ID : gridType === 'All Properties/Upcoming' ? '' : gridRowId} ${gridType === 'All Properties/Upcoming' ? ' ' : ' - '} ${p.propertyAddress} ${p.propertyTitle ? `- ${p.propertyTitle}` : ''}`}</span>
                                                            </p>
                                                            <button
                                                                onClick={() => onDeletePropertyFromModal(p.propertyId, p.contactId)}
                                                                type={'button'}
                                                            >
                                                                <svg width="12" height="12" viewBox="0 0 12 12"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_1387_36685)">
                                                                        <path
                                                                            d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                            fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_1387_36685">
                                                                            <rect width="12" height="12"
                                                                                  fill="white"/>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>

                                                            </button>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    surfacesForEmail.length
                                        ?
                                        <div className={s.messageInner__properties}>
                                            <p>Surfaces:</p>
                                            <div className={s.messageInner__propertiesInner}>
                                                {surfacesForEmail.map((p: any, index: number) => {
                                                    const hyperLink = `${propertiesLinkStart}/#/s/${gridRowId}-${p.propertyId}-${p.surfaceId}-${contactLang}-${emailDomain.message}`
                                                    return (
                                                        <div className={s.messageInner__property} key={index}>
                                                            <a href={hyperLink} target={'_blank'}
                                                               rel="noreferrer">
                                                                <span>{`${gridRowId} - ${p.propertyId} - ${p.surfaceId} - ${p.propertyAddress} ${p.propertyTitle ? `- ${p.propertyTitle}` : ''}`}</span>
                                                            </a>
                                                            <button
                                                                onClick={() => onDeleteSurfaceFromModal(p.surfaceId)}
                                                                type={'button'}
                                                            >
                                                                <svg width="12" height="12"
                                                                     viewBox="0 0 12 12"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <g clipPath="url(#clip0_1387_36685)">
                                                                        <path
                                                                            d="M6.46967 5.99974L9.233 3.2364C9.28761 3.17264 9.31614 3.09061 9.3129 3.00672C9.30966 2.92283 9.27488 2.84325 9.21552 2.78388C9.15616 2.72452 9.07658 2.68974 8.99268 2.6865C8.90879 2.68326 8.82677 2.71179 8.763 2.7664L5.99967 5.52974L3.23633 2.76307C3.17356 2.7003 3.08843 2.66504 2.99967 2.66504C2.9109 2.66504 2.82577 2.7003 2.763 2.76307C2.70023 2.82584 2.66497 2.91097 2.66497 2.99974C2.66497 3.0885 2.70023 3.17364 2.763 3.2364L5.52967 5.99974L2.763 8.76307C2.7281 8.79295 2.69976 8.82972 2.67976 8.87108C2.65975 8.91243 2.64851 8.95748 2.64673 9.00338C2.64496 9.04929 2.65269 9.09507 2.66945 9.13784C2.68621 9.18062 2.71163 9.21947 2.74412 9.25195C2.7766 9.28444 2.81545 9.30986 2.85822 9.32661C2.901 9.34337 2.94678 9.35111 2.99268 9.34933C3.03859 9.34756 3.08363 9.33632 3.12499 9.31631C3.16634 9.2963 3.20312 9.26796 3.233 9.23307L5.99967 6.46974L8.763 9.23307C8.82677 9.28768 8.90879 9.31621 8.99268 9.31297C9.07658 9.30973 9.15616 9.27496 9.21552 9.21559C9.27488 9.15623 9.30966 9.07665 9.3129 8.99275C9.31614 8.90886 9.28761 8.82684 9.233 8.76307L6.46967 5.99974Z"
                                                                            fill={'rgba(0, 0, 0, 0.54)'}/>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_1387_36685">
                                                                            <rect width="12" height="12"
                                                                                  fill="white"/>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>

                                                            </button>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        :
                                        gridType === 'All Properties/Upcoming' && isModalHided
                                            ?
                                            <div style={{
                                                position:'absolute',
                                                bottom: '0',
                                                backgroundColor: '#000'
                                            }}>
                                                hided
                                            </div>
                                            :
                                            null
                                }
                                {
                                    (gridType === 'All Properties/Upcoming' && !propertiesForEmail.length) ||
                                    (gridType === 'Properties' && (!surfacesForEmail.length && !propertiesForEmail.length))
                                        ?
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                            zIndex: 9999
                                        }}>
                                            <p style={{
                                                color: 'rgba(0, 0, 0, 0.54)',
                                                fontSize: '14px',
                                                lineHeight: '16px',
                                                marginTop: '200px'
                                            }}>Please select properties to view message body and send an offer</p>
                                        </div>
                                        :
                                        <div className={s.messageInner__message}>
                                            <p className={s.messageInner__messageText}>Message</p>
                                            <Form.Item
                                                name='message'
                                                initialValue={emailBodyTextReplaced}
                                            >
                                                <PropertyEditableMessage textValue={emailBodyTextReplaced}/>
                                            </Form.Item>

                                        </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>
                <Form.Item style={{
                    display: 'flex',
                    justifyContent: "flex-end",
                    width: '100%',
                    padding: '20px 16px 25px 16px'
                }}
                >
                    <Button
                        type='primary'
                        htmlType='submit'
                        style={{
                            height: '40px',
                            backgroundColor: '#1755E7',
                            width: '100%',
                            border: '1px solid #1755E7',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                        }}
                        disabled={clickedSendBtn || (gridType === 'All Properties/Upcoming' && !propertiesForEmail.length)}
                        loading={isLoading}
                        // onDoubleClick={() => setIsLoading(true)}
                        // disabled={false}
                        // loading={false}
                        // onDoubleClick={() => setIsLoading(false)}
                    >
                        {clickedSendBtn && <LoadingOutlined style={{fontSize: 24, color: '#FFF'}} spin/>}
                        Send
                    </Button>
                </Form.Item>
            </Form>
            :
            (gridType === 'All Properties/Upcoming' || gridType === 'Properties') && isModalHided
                ?
                <div
                    className={s.messageInner__header}
                    style={{
                        padding: '12px 12px 11px 16px',
                        borderRadius: '8px 8px 0 0',
                        height: '50px',
                        position: 'fixed',
                        bottom: '0',
                        width: '250px',
                        right: '50px',
                        zIndex: '9999'
                    }}
                >
                    <p
                        style={{
                            fontSize: '14px'
                        }}
                    >New email</p>
                    <div className={s.messageInner__buttons}>
                        <button
                            type={'button'}
                            onClick={unHidePropContactOfferModal}
                        >

                            <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10 4.44444V0H5.55556L7.38333 1.82778L1.82778 7.38333L0 5.55556V10H4.44444L2.61667 8.17222L8.17222 2.61667L10 4.44444Z"
                                    fill="white" fillOpacity="0.6"/>
                            </svg>



                        </button>
                        <button
                            type={'button'}
                            onClick={onCloseOfferModal}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0_1387_34908)">
                                    <path
                                        d="M4.66667 3L3 4.66667L6.33333 8L3 11.3333L4.66667 13L8 9.66667L11.3333 13L13 11.3333L9.66667 8L13 4.66667L11.3333 3L8 6.33333L4.66667 3Z"
                                        fill="white" fillOpacity="0.6"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1387_34908">
                                        <rect width="10" height="10" fill="white"
                                              transform="matrix(-1 0 0 -1 13 13)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </button>
                    </div>
                </div>
                :
                null


    );
};

export default PropertiesOfferModal;
